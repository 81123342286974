import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Organization } from '@bp/shared/domains/organizations';
import { RecordsPage } from '@bp/shared/models/common';
import { DTO } from '@bp/shared/models/metadata';

import { PaymentRoute } from '@bp/admins-shared/core/models';

import { CURRENCY_MARKUPS_NAMESPACE, CurrencyMarkups } from '@bp/merchant-admin/frontend/domains/currency-markups';

@Injectable({
	providedIn: 'root',
})
export class CurrentOrganizationApiService {
	private readonly _namespace = 'merchant';

	constructor(private readonly _http: HttpClient) {}

	getCurrent(): Observable<Organization> {
		return this._http
			.get<DTO<Organization>>(this._namespace)
			.pipe(map(organization => new Organization(organization)));
	}

	getPaymentRoutes(): Observable<PaymentRoute[]> {
		return this._http
			.get<RecordsPage<DTO<PaymentRoute>>>('payment-routes', { params: { limit: 99_999 } })
			.pipe(map(it => it.records.map(v => new PaymentRoute(v))));
	}

	getCurrencyMarkups(): Observable<CurrencyMarkups> {
		return this._http
			.get<DTO<CurrencyMarkups>>(`${ this._namespace }/${ CURRENCY_MARKUPS_NAMESPACE }`)
			.pipe(map(dto => new CurrencyMarkups(dto)));
	}

	saveCurrencyMarkups(currencyMarkups: CurrencyMarkups): Observable<void> {
		return this._http.post<void>(`${ this._namespace }/${ CURRENCY_MARKUPS_NAMESPACE }`, currencyMarkups);
	}
}
